import { useState } from "react";
import { ResponseWalletAdded, USER_TYPE, WalletInfo, addWalletToUser, selectJWT, selectUserInfo, selectWallet, setError, setSuccess, setUserInfo } from "../features/user/user";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { Form, Modal } from "react-bootstrap";
import { useAppDispatch } from "../app/hooks";
import { UserService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import AddAssetForm from "./AddAssetForm";
import { useTranslation } from "react-i18next";



function WalletsData( {setInquiryId} : any) {

    const [dropDownWallet, setDropdownWallet] = useState(false);
    const [walletCopied, setWalletCopied] = useState(-1);
    const userInfo = useSelector(selectUserInfo);
    const jwt = useSelector(selectJWT);
    const wallet = useSelector(selectWallet);
    const [jwtWalletCopied, setJwtWalletCopied] = useState(false);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const [jwtWallet, setJWTWallet] = useState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showAddToken, setShowAddToken] = useState(false);
    const { t } = useTranslation('common');
    const [ currency, setCurrency ] = useState('USD $');


    const getUrlAccount = async () => {
        const jwtAddAccount = await UserService.getUrlForVinculate(jwt);
        setJWTWallet(jwtAddAccount.jwtUrl);
    }

    const copyWallet = (index: number) => {
        //console.log("ELIJO WALLET: ", userInfo.wallets ? userInfo.wallets[index]  : 'error');
        navigator.clipboard.writeText(userInfo.wallets ? userInfo.wallets[index] : '');
        setWalletCopied(index);
        setTimeout(() => setWalletCopied(-1), 3000);
    };

    const addAccount = async () => {

        try {

            const provider = new ethers.BrowserProvider((window as any).ethereum);
            const resp: any = await provider.send('wallet_requestPermissions', [{
                eth_accounts: {}
            }]);
            const walletsConnected: string[] = [];
            resp[0]?.caveats[0]?.value.forEach((walletConnected: string) => {
                if (walletConnected !== wallet) {
                    walletsConnected.push(walletConnected);
                }
            });

            const saved: WalletInfo[] = await UserService.saveWalletsToUser(walletsConnected,jwt);
            if (saved) {
                saved.forEach((userWallet: WalletInfo) => {
                    if (userWallet.wallet) {
                        dispatch(addWalletToUser(userWallet.wallet))
                    }
                });
                setShowWalletModal(false);
                dispatch(setSuccess({ title: "Wallet linked!", desc: "Your wallet was vinculated to existent user" }));
            }
            else {
                setShowWalletModal(false);
                dispatch(setError({ title: "Error DApp", desc: "Error when trying to add wallets" }));
            }
        } catch (error) {
            setShowWalletModal(false);
            dispatch(setError({ title: "Error DApp", desc: "Error when trying to add wallets" }));
        }

    }

    
    const copyJWTWallet = () => {
        navigator.clipboard.writeText(jwtWallet ? jwtWallet : '');
        setJwtWalletCopied(true);
    };

    const handleForm = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        // setLoading(true);

        const target = event.target as typeof event.target & {
            jwtWallet: { value: string };
        };


        const data: any = {
            jwt: target.jwtWallet.value
        }

        //console.log("ENVIAMOS A API JWT DATA: ", data);

        const saved: ResponseWalletAdded = await UserService.saveWalletWithJWT(data, jwt);

        //console.log("SAVED: ", saved);
        if (saved?.user?._id) {
            setShowWalletModal(false);
            dispatch(setSuccess({ title: "Wallet linked!", desc: "Your wallet was vinculated to existent user" }));
            dispatch(setUserInfo({ _id: saved.user._id, userType: saved.user.userType, wallets: saved.wallets }))
            // setSuccesfull('Account vinculated correctly')
        }
        else {
            setShowWalletModal(false);
            dispatch(setError({ title: "Error DApp", desc: "Error when trying connect to other account" }));
        }

    };

    return (
        <>
            <div className="row row-gap-3">
                <h1 className="h3 me-4 col-auto">{t('components.walletsData.accounts')}</h1>
                {userInfo.userType !== USER_TYPE.ADMIN && (
                <div className=" col-auto">
                    <div className="text-nowrap">
                    {userInfo.creditsAvailable !== undefined && userInfo.creditsAvailable > 0 &&  (<button className="ml-2 btn btn-outline-primary pe-none text-nowrap ">
                        <i className="bi bi-coin"></i>
                            <span className="ms-2">{t('components.walletsData.credits')}: {userInfo?.creditsAvailable && userInfo.creditsAvailable >= 0 ? userInfo.creditsAvailable : 0}</span>
                    </button>)}
                    {userInfo.creditsAvailable !== undefined && userInfo.creditsAvailable <= 0 && (
                        <button className="ml-2 btn btn-outline-primary text-nowrap" onClick={ () => {console.log("CLICK") ; navigate('/plans')}}>
                            <i className="bi bi-cart"></i>
                                <span className="ms-2">{t('components.walletsData.buyCredits')}</span>
                        </button>)}
                    </div>
                </div>)}
                {userInfo.userType === USER_TYPE.ADMIN && (
                    <div className="col-auto">
                        <button className="ml-2 btn btn-outline-primary pe-none text-nowrap text-valido-ranking">
                        <i className="bi bi-gear-fill"></i>
                            <span className="ms-2">{t('components.walletsData.admin')}</span>
                    </button>
                    </div>
                )}
                <div className="col-auto">
                    <div className="text-nowrap">
                        <div className="btn-group btn-group-sm me-1">
                            <button className="btn btn-outline-primary pe-none text-nowrap btn-image-sm border-end-none" style={{minHeight : '32px'}}>
                                <i className="bi bi-wallet2"></i>
                                <span className="ms-2">{wallet}</span>
                            </button>
                            <button type="button" onClick={() => setDropdownWallet(!dropDownWallet)} className={dropDownWallet ? 'btn btn-outline-primary dropdown-toggle dropdown-toggle-split pe-2 border-start-none show' : 'btn btn-outline-primary dropdown-toggle dropdown-toggle-split pe-2 border-start-none'}
                                data-bs-auto-close="outside" aria-expanded={dropDownWallet ? "true" : false} data-bs-reference="parent">
                                {/* <span className="visually-hidden">Toggle Dropdown</span> */}
                            </button>
                            <div className={dropDownWallet ? "dropdown-menu overflow-hidden show" : "dropdown-menu overflow-hidden"} data-bs-theme="light"
                                style={dropDownWallet ? { position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate3d(0px, 30px, 0px)' } : {}}>
                                <div className="row text-bg-primary px-3 py-2 justify-content-between" style={{ marginTop: "-8px" }}>
                                    <div className="col-auto">
                                        <span>{t('components.walletsData.accounts')}</span>
                                    </div>
                                    {/* <div className="col-auto">
                                        <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                            <input className="form-check-input border-white" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                            <label className="form-check-label">Deselect All</label>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row text-dark px-3 py-1" style={{ marginBottom: "-8px", backgroundColor: "var(--bs-white)" }}>
                                    {userInfo.wallets && (

                                        <table className="table table-sm table-striped table-hover table-borderless align-middle m-0">
                                            <tbody>
                                                {userInfo.wallets.map((wallet: string, index: number) => (
                                                    <tr key={index}>
                                                        <td className="text-nowrap">
                                                            <span className="btn-image-md"><i className="bi bi-wallet"></i>
                                                            <span className="ms-2 align-middle-md"><small>{wallet}</small></span></span></td>
                                                        {/* <td className="text-nowrap text-muted"><small>{wallet}</small></td> */}
                                                        <td className="text-nowrap pointer" onClick={() => { copyWallet(index) }}><i className={walletCopied === index ? "bi bi-clipboard-check-fill" : "bi bi-clipboard-plus"} ></i></td>
                                                        <td className="text-nowrap"><i className="bi bi-three-dots-vertical" ></i></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    <button className="btn btn-light w-100 my-1 text-start" aria-current="page" onClick={() => { setShowWalletModal(true); getUrlAccount() }}>
                                        <i className="bi bi-plus-circle"></i><span className="ms-3">{t('components.walletsData.addWallet')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-group btn-group-sm me-1">
                            <button type="button" className="btn btn-outline-primary pe-none text-nowrap btn-image-sm border-end-none">
                                <img className="rounded-circle" src="./assets/img/imagen_02.png" alt="Imagen" /><img className="rounded-circle ms--1" src="./assets/img/imagen_03.png" alt="Imagen" /><img className="rounded-circle ms--1" src="./assets/img/imagen_04.png" alt="Imagen" /><span className="ms-2">3 Networks</span>
                            </button>
                            <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split pe-2 border-start-none" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <div className="dropdown-menu overflow-hidden" style={{ minWidth: "18rem" }} data-bs-theme="light">
                                
                                <div className="row text-bg-primary px-3 py-2 justify-content-between">
                                    <div className="col-auto">
                                        <span>Active Networks</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                            <input className="form-check-input border-white" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                            <label className="form-check-label">Deselect All</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-dark px-3 py-1" style={{ marginBottom: "-8px", backgroundColor: "var(--bs-white)" }}>
                                    <table className="table table-sm table-striped table-hover table-borderless align-middle m-0">
                                        <tbody>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€9999990.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€0.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    
                </div>
                <div className="col-auto ms-auto">
                    <div className="text-nowrap">
                        {/* {window.location.hash.includes('inquiry') && (
                            <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page" onClick={() => setShowAddToken(true)}>
                                <i className="bi bi-plus-square "></i><span className="ms-3">Request Add Asset</span>
                            </button>
                        )} */}
                        {/* <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page">
                            <i className="bi bi-save "></i><span className="ms-3">Exportar</span>
                        </button> */}
                        {/* <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page">
                            <i className="bi bi-file-earmark-check "></i><span className="ms-3">Generar Certificado</span>
                        </button>  */}
                        <button className="ml-2 btn btn-outline-primary text-nowrap" onClick={() => {setCurrency( currency === 'EUR €' ? 'USD $' : 'EUR €' )}}>{currency}</button>&nbsp;&nbsp;
                        <button className="btn btn-primary btn-sm px-3 text-nowrap" 
                            onClick={ () => {if(window.location.hash.includes('inquiry')) {setInquiryId(undefined)} else navigate('/inquiry')}} aria-current="page">
                            <i className="bi bi-file-earmark-check "></i><span className="ms-3">{t('components.walletsData.newInquiry')}</span>
                        </button>
                        
                    </div>
                </div>
            </div>
            <Modal
                onHide={() => { setShowWalletModal(false) }}
                show={showWalletModal}
                size='lg'
                centered
            >
                <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setShowWalletModal(false) }} aria-label="Close"></button>
                <div className="container">
                    <div className="p-3 my-4 text-center">
                        <div className="mb-4">
                            <i className="display-5 text-info-ranking bi bi-wallet2"></i>
                        </div>
                        <h1 className="h2">{t('components.walletsData.connectWallet')}</h1>
                        <p className="lead">{t('components.walletsData.conectMoreThanOne')} 💡</p>
                        <div className="mt-4 d-grid gap-3 text-start">
                            <div className="row">
                                <div className="col d-grid">
                                    <button type="button" className="btn btn-primary" onClick={addAccount}>
                                        <img src="./assets/img/metamask.png" style={{ maxHeight: "32px" }} alt="img-metamask" /> <span className="ms-2">{t('components.walletsData.connectMetamask')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {jwtWallet && (
                            <>
                                <div className="row position-relative my-4">
                                    <span className="position-absolute top-50 start-50 translate-middle lead"></span>
                                    <div className="col-6"><hr /></div>
                                    <div className="col-6"><hr /></div>
                                </div>
                                <p className="text-start ms-2 text-center">{t('components.walletsData.codeJWT')}</p>
                                <form className="input-group mb-3 needs-validation" >
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Username" aria-describedby="button-addon2" disabled />
                                        <label>{jwtWallet}</label>
                                    </div>
                                    <button className="btn btn-primary" type="submit" id="button-addon2" disabled={jwtWalletCopied} onClick={copyJWTWallet}>{jwtWalletCopied ? 'Copied!' : 'Copy'}</button>
                                </form>
                            </>
                        )}
                        {!userInfo?._id && (
                            <>
                                <div className="row position-relative my-4">
                                    <span className="position-absolute top-50 start-50 translate-middle lead"></span>
                                    <div className="col-6"><hr /></div>
                                    <div className="col-6"><hr /></div>
                                </div>
                                <p className="text-start ms-2 text-center">{t('components.walletsData.connectAccountExistent')}</p>
                                <Form className="input-group mb-3 needs-validation" onSubmit={handleForm}>
                                    <Form.Group className="form-floating" controlId="jwtWallet">
                                        <Form.Control type="text" placeholder={"eyJ0eXAiO...."} required />
                                        <Form.Label>{t('components.walletsData.codeJWT')}</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                    <button className="btn btn-primary" type="submit" id="button-addon2">{t('components.walletsData.vinculate')}</button>
                                </Form>
                                {/* <form className="input-group mb-3 needs-validation" >
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Username" aria-describedby="button-addon2" required />
                                        <label>Código único de cuenta BalanceShot</label>
                                </div>
                            </form> */}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                onHide={() => { setShowAddToken(false) }}
                show={showAddToken}
                size="lg"
            >
                <AddAssetForm setShowAddToken={setShowAddToken}/>
            </Modal>
        </>
    )
}

export default WalletsData;