import { useSelector } from 'react-redux';
import Header from './components/Header';
import { selectError, cleanError, selectJWT, selectSuccess, cleanSuccess } from './features/user/user';
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { useAppDispatch } from './app/hooks';
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function App() {

  const jwt = useSelector(selectJWT);
  const error = useSelector(selectError);
  const success = useSelector(selectSuccess);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("USE EFFECT: ", error);
    if (!jwt) {
      navigate("/");
    }
    
  }, [error, jwt, navigate])


  return (
    <>
      <div className='row m-0 p-0 overflow-hidden'>
        {jwt && (<Header />)}
        <Outlet />
      </div>
      <Modal
        onHide={() => { dispatch(cleanError()) }}
        show={error?.desc !== undefined}
        size="lg"
        centered
      >
        <>
          <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(cleanError())}></button>
          <div className="container">
            <div className="p-3 my-4 text-center">
              <div className="mb-4">
                <i className="display-5 text-info-ranking bi bi-question-square"></i>
              </div>
              <h1 className="h2">Algo fue mal</h1>
              <p style={{ color: 'red' }}>{error?.desc}</p>
              <p>Para contactar con nuestro el soporte, completa el siguiente formulario.</p>
              <form className="form-floating-grup mt-4 d-grid gap-3 text-start needs-validation">
                <div className=''>
                  <div className="form-floating">
                    <input type="email" className="form-control rounded-0 rounded-top-2" id="floatingInput" placeholder="name@example.com" required />
                    <label>Email</label>
                  </div>
                  <div className="form-floating">
                    <input type="text" className="form-control rounded-0" id="Asunto" required />
                    <label>Asunto</label>
                  </div>
                  <div className="form-floating">
                    <textarea className="form-control rounded-0 rounded-bottom-2" id="floatingTextarea" placeholder="Comentarios" required></textarea>
                    <label >Comentarios</label>
                  </div>
                </div>
                <div className="form-check">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <input className="form-check-input" type="checkbox" value="" id="legalCheck" required />
                    </div>
                    <div className="col-auto m-0 p-0">
                      <label className="form-check-label">
                        Aceptar Política y Condiciones
                      </label>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Conectar
                </button>
              </form>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        onHide={() => { dispatch(cleanSuccess()) }}
        show={success?.desc !== undefined}
        size="sm"
        centered
      >
        <>
          <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(cleanSuccess())}></button>
          <div className="container">
            <div className="p-3 my-4 text-center">
              <div className="p-3 my-4 text-center">
                <div className="mb-4">
                  <i className="display-5 text-info-ranking bi bi-check2-square"></i>
                </div>
                <h1 className="h2">Successful!</h1>
              </div>
              <h2>{success?.title}</h2>
              <br />
              <p>{success?.desc}</p>
          </div>
        </div>
      </>
    </Modal >
    </>
  );
}

export default App;