import axios from "axios";
import { Plan } from "../models/models";
import { InvoiceData } from "../features/user/user";
import { UserData } from "../features/user/user";

export class UserService {

    static async getUrlForVinculate( jwt : string) : Promise<any>{
        
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/url-vinculate';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }

    static async saveWalletsToUser ( wallets : any, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/wallet-to-user';
        const resp = await axios.post(url, { wallets }, { headers: { 'authorization': jwt } });
        return resp?.data?.result;

    }

    static async saveWalletWithJWT ( body : any, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/wallet-to-user';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }

    static async getPlans(jwt : string) : Promise<Plan[]> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/plans';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }

    static async addToken ( body : any, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/tokens';
        const resp = await axios.put(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }


    static async saveProfileData ( body : UserData, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/profile-data';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }

    static async saveInvoiceData ( body : InvoiceData, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/invoice-data';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }

    
}