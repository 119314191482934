import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { ResponseUpdateTokenApi } from "../models/models";
import { modifyToken, selectJWT } from "../features/user/user";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/hooks";
import { useTranslation } from "react-i18next";



export type FormProps = {
    idToken: string
}

function FormToken({ idToken }: FormProps) {

    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);
    const jwt = useSelector(selectJWT);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');

    const handleForm = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        setLoading(true);


        const target = event.target as typeof event.target & {
            trustLevel: { value: number };
        };


        const data: any = {
            idToken,
            token: {
                trustLevel: target.trustLevel.value
            }
        }


        //console.log("DATOS FORM : ", data);
        const response: ResponseUpdateTokenApi = await callApi(data);

        if (response) {
            console.log("RESPONSE: ", response);
            setUpdated(true)
            setLoading(false);
            dispatch(modifyToken({_id : idToken, trustLevel: Number(data.token.trustLevel)}))
        }
        else {
            setLoading(false);
            alert("Error updating token");
        }


    };

    const callApi = async (body: any) => {

        try {
            const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/tokens';
            const resp = await axios.post(url, body,  { headers: { 'authorization': jwt } });
            //console.log("RESP", resp);
            return resp?.data?.result;
        } catch (error: any) {
            console.error('Error captured:', error);
            return undefined;
        }

    }

    useEffect(() => {
        //console.log("ID TOKEN A MODIFICAR: ", idToken);
    }, [idToken]);



    return (
        <>
            <Form className="row d-flex justify-content-center m-2" onSubmit={handleForm}>
                <Form.Group className="col-12" controlId="trustLevel">
                    <Form.Label>{t('components.formToken.confidenceLevel')}</Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option value="0">{t('components.formToken.notDefined')}</option>
                        <option value="1">{t('components.formToken.pending')}</option>
                        <option value="2">{t('components.formToken.suspicious')}</option>
                        <option value="3">{t('components.formToken.scam')}</option>
                        <option value="4">{t('components.formToken.valid')}</option>
                    </Form.Select>
                </Form.Group>
                {!loading && !updated && (<Button className="mt-4" variant="primary" type="submit">
                    {t('components.formToken.editToken')}
                </Button>)}
                {loading && (
                    <div className='col-lg-12 m-2'>
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="secondary" />
                    </div>
                )}
                { updated && (
                    <div className='col-lg-12 m-2'>
                        <p> {t('components.formToken.modifiedConfidenceLevel')}</p>
                    </div>
                )}
            </Form>
        </>);
}

export default FormToken;