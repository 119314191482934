import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap/dist/js/bootstrap.js';
import './custom.css';
import './custom.css.map';
import { store } from './app/store';
import { Provider } from 'react-redux';
import Login from './views/Login';
import {
  createHashRouter,
  RouterProvider
} from "react-router-dom";
import Plans from './views/Plans';
import Profile from './views/Profile';
import Inquiry from './views/Inquiry';
import Balance from './views/BalanceView';
import Historic from './views/HistoricView';
import InquiryDetail from './views/InquiryDetail';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import Tokens from './views/Tokens';
import Users from './views/Users';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'es',                              // language to use
  resources: {
    en: {
      common: common_en
    },
    es: {
      common: common_es
    },
  },
});

const router = createHashRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: '/',
        element: <Login/>
      },
      {
        path: '/balance',
        element: <Balance/>
      },
      {
        path: '/inquiry',
        element: <Inquiry/>
      },
      {
        path: '/historic',
        element: <Historic/>,
      },
      {
        path: '/tokens',
        element: <Tokens/>,
      },
      {
        path: '/users',
        element: <Users/>,
      },
      {
        path: '/details/:inquiryId',
        element: <InquiryDetail/>
      },
      {
        path: '/plans',
        element: <Plans/>
      },
      {
        path: '/profile',
        element: <Profile/>
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
    </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

