import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BLOCKCHAIN, Balance, CurrentBalance } from "../models/models";
import { Token } from "../models/models";


type FilterProps = {
    balances?: Balance[];
    currentBalances?: CurrentBalance[];
    iconBlockchain: any;
    setFiltered: any;
    enableAllFilters : boolean, 
    setEnableAllFilters : any
}

type BlockchainSelected = {
    blockchain: BLOCKCHAIN;
    active: boolean
}

type WalletSelected = {
    wallet: string;
    active: boolean
}

type FilterValues = {
    blockchains: BlockchainSelected[];
    bcs: BLOCKCHAIN[];
    wallets?: WalletSelected[];
    showErc20: boolean;
    showNative: boolean;
    showZero: boolean;
}


function Filters({ balances, currentBalances, iconBlockchain, setFiltered, enableAllFilters, setEnableAllFilters }: FilterProps) {

    const { t } = useTranslation('common');
    const [filterValues, setFilterValues] = useState<FilterValues>({
        showErc20: true,
        showZero: true,
        showNative: true,
        bcs: [BLOCKCHAIN.ETHEREUM, BLOCKCHAIN.BSC, BLOCKCHAIN.POLYGON],
        blockchains: [{ blockchain: BLOCKCHAIN.BSC, active: true }, { blockchain: BLOCKCHAIN.ETHEREUM, active: true }, { blockchain: BLOCKCHAIN.POLYGON, active: true }],
        wallets : currentBalances?.map( (bal: CurrentBalance) => { return {wallet : bal.wallet, active : true}} )
    });
    const [showWallets, setShowWallets] = useState<boolean>(false);
    // const [balancesFiltered, setBalancesFiltered] = useState<CurrentBalance[]>(balances);

    const handleBlockchainFilter = (blockchain: BLOCKCHAIN) => {
        if (filterValues.blockchains.find((bc: BlockchainSelected) => bc?.blockchain === blockchain)) {
            filterValues.blockchains?.forEach((bc: BlockchainSelected) => { if (blockchain === bc?.blockchain) bc.active = !bc.active });
        }
        else {
            filterValues.blockchains.push({ blockchain, active: true });
        }

        const bcsNew: BLOCKCHAIN[] = [];
        filterValues.blockchains?.forEach((bc: BlockchainSelected) => { if (bc.active) bcsNew.push(bc?.blockchain) })

        setFilterValues({ ...filterValues, bcs: bcsNew });
    };

    const handleWalletFilter = (value: string) => {
        const newValueWallets : WalletSelected[] = [];
        
        filterValues.wallets?.forEach( (wal : WalletSelected) => {
            if(wal.wallet === value){
                newValueWallets.push( {wallet : wal.wallet, active : !wal.active})
            }
            else{
                newValueWallets.push( {wallet : wal.wallet, active : wal.active})
            }
        })
        setFilterValues({ ...filterValues, wallets: newValueWallets });
    };

    const handleErc20Filter = () => {
        setFilterValues({ ...filterValues, showErc20: !filterValues.showErc20 })
    };

    const handlNativeFilter = () => {
        setFilterValues({ ...filterValues, showNative: !filterValues.showNative })
    };

    const handleZeroFilter = () => {
        setFilterValues({ ...filterValues, showZero: !filterValues.showZero });
    };

    const isActive = (blockchain: BLOCKCHAIN) => {
        return filterValues.blockchains.find((bc: BlockchainSelected) => bc?.blockchain === blockchain)?.active;
    }


    useEffect(() => {
        const filterBalances = (balances: Balance[]) => {
            const newBalances: Balance[] = []
            balances.forEach((balance: Balance) => {


                const tokens: Token[] = balance?.tokens.filter((token: Token) => {
                    if (!filterValues.showZero && !(token.balance > 0)) {
                        return false;
                    }
                    if (filterValues.showNative && filterValues.showErc20) {
                        return true;
                    }
                    if (filterValues.showNative && token.address) {
                        return false;
                    }
                    if (filterValues.showErc20 && !token.address) {
                        return false;
                    }
                    return true;
                })
                if (filterValues.bcs.includes(balance?.blockchain)) { newBalances.push({ ...balance, tokens }) }
            });

            return newBalances;
        }

        const enableFilters = ( ) => {
    
            setFilterValues({
                showErc20: true,
                showZero: true,
                showNative: true,
                bcs: [BLOCKCHAIN.ETHEREUM, BLOCKCHAIN.BSC, BLOCKCHAIN.POLYGON],
                blockchains: [{ blockchain: BLOCKCHAIN.BSC, active: true }, { blockchain: BLOCKCHAIN.ETHEREUM, active: true }, { blockchain: BLOCKCHAIN.POLYGON, active: true }],
                wallets : currentBalances?.map( (bal: CurrentBalance) => { return {wallet : bal.wallet, active : true}} )
            });
        }

        const applyFilters = () => {
            
            console.log("Enable : ", enableAllFilters);
            if( enableAllFilters){
                enableFilters();
                setEnableAllFilters(false);
            }
            else if (balances) {
                const filteredBalances = filterBalances(balances);
                setFiltered(filteredBalances);
            }
            else if (currentBalances) {
                const newCurrentBalances: CurrentBalance[] = [];
                currentBalances.forEach((bal: CurrentBalance) => {
                    if (bal && filterValues.wallets?.find( (wal ) => wal.wallet === bal.wallet && wal.active)) {
                        const newBalance: CurrentBalance = { wallet: bal.wallet, balance: filterBalances(bal.balance) };
                        newCurrentBalances.push(newBalance);
                    }
                });
                setFiltered(newCurrentBalances);
            }
        }

        applyFilters();

    }, [enableAllFilters,currentBalances, balances, filterValues, setFiltered]);

    const getClass = ( balance : CurrentBalance) =>{
        const classWallet : string = filterValues.wallets?.find( (wal ) => wal.wallet === balance.wallet && wal.active) ? 
            "m-2 btn btn-primary btn-wallets-2 w-100 my-1 text-center selected" : 
            "m-2 btn btn-light btn-wallets-2 w-100 my-1 text-center selected";
        return  classWallet ;
    }

    return (
        <div className="row px-1 py-4 rounded-top-4 mt-auto text-light-emphasis bg-light-subtle">
            <div className="row row-gap-3 me-1">
                <div className="col-12 my-auto">
                    {currentBalances && (
                            <div className="col d-flex justify-content-center">
                            <div className="dropdown text-nowrap">
                                {currentBalances.map((balance: CurrentBalance, index: number) => (
                                    <div key={index} onClick={() => { handleWalletFilter( balance.wallet) }}
                                        className={getClass(balance)}>
                                            <i className="bi bi-wallet"></i>&nbsp;&nbsp;
                                        {balance.wallet.length > 20
                                                ? `${balance.wallet.slice(0, 6)}...${balance.wallet.slice(-6)}`
                                                : balance.wallet}
                                    </div>
                                ))}

                                </div>
                            </div>
                        
                    )}
                </div>
                <div className="col-12 my-auto ms-auto d-flex justify-content-center ">
                    <div className="row row-gap-3">
                        <div className="col-auto my-auto">
                            <div  className={"" + (isActive(BLOCKCHAIN.ETHEREUM) ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                                    onClick={() => handleBlockchainFilter(BLOCKCHAIN.ETHEREUM)}>
                                        <span className="btn-image-md">
                                            <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                                        </span>
                                        {t('components.filters.eth')}
                                </div>
                                <div  className={"" + (isActive(BLOCKCHAIN.POLYGON) ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                                    onClick={() => handleBlockchainFilter(BLOCKCHAIN.POLYGON)}>
                                        <span className="btn-image-md">
                                            <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                                        </span>
                                        {t('components.filters.poly')}
                                </div>
                                <div  className={"" + (isActive(BLOCKCHAIN.BSC) ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                                    onClick={() => handleBlockchainFilter(BLOCKCHAIN.BSC)}>
                                        <span className="btn-image-md">
                                            <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                                        </span>
                                        {t('components.filters.bsc')}
                                </div>
                        </div>
                        <div className="col-auto my-auto">

                            <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={handleZeroFilter} checked={filterValues.showZero} />
                                <label className="form-check-label" >{t('components.filters.zeroBalance')}</label>
                            </div>
                        </div>
                        <div className="col-auto my-auto">

                            <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={handleErc20Filter} checked={filterValues.showErc20} />
                                <label className="form-check-label" >{t('components.filters.showToken')}</label>
                            </div>
                        </div>
                        <div className="col-auto my-auto">


                            <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={handlNativeFilter} checked={filterValues.showNative} />
                                <label className="form-check-label" >{t('components.filters.showNative')}</label>
                            </div>
                        </div>
                        {/* <div className="col-auto my-auto">
                                            <div className="btn-group btn-group-sm text-nowrap">
                                                <button type="button" className="btn btn-outline-primary pe-none btn-image-sm border-end-none">
                                                    <span className="ms-2">List view</span>
                                                </button>
                                                <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split pe-2 border-start-none" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                                                    <span className="visually-hidden">Toggle Dropdown</span>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="/">Action</a></li>
                                                    <li><a className="dropdown-item" href="/">Another action</a></li>
                                                    <li><a className="dropdown-item" href="/">Something else here</a></li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li><a className="dropdown-item" href="/">Separated link</a></li>
                                                </ul>
                                            </div>
                                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filters;