import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { PaymentIntentResult } from "@stripe/stripe-js";
import { Button, Form } from "react-bootstrap";
import { PaymentData } from "../models/models";
import axios from "axios";
import { useSelector } from "react-redux";
import { addCreditsAvailable, addCreditsConsumed, selectJWT, setInquiryConfirmed } from "../features/user/user";
import { useAppDispatch } from "../app/hooks";
import PaymentService from "../services/payment.service";
import Spinner from "./Spinner";
import { useTranslation } from "react-i18next";

export type FormProps = {
  paymentData: PaymentData,
  inquiryId?: string,
  certificateId? : string,
  planId? : string
  credits : number
  setModalPayment: any,
  setInquiryId: any,
  setCorrectPayment: any,
  isPayingInquiry: boolean // Validar que si se está pagando una Inquiry o únicamente comprando créditos
}

export default function CheckoutForm({ paymentData, inquiryId,certificateId, planId, credits, setModalPayment, setInquiryId, setCorrectPayment, isPayingInquiry }: FormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const jwt = useSelector(selectJWT);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {

    const paidApi = async (body: any) => {

      try {
        //console.log("LLAMO API")
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/payments/paid';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        //console.log("RESP", resp);
        return resp?.data?.result;
      } catch (error: any) {
        console.error('Error captured:', error);
        return undefined;
      }

    }

    if (!stripe) {
      return;
    }
    let clientSecret: any;
    let paymentId: any;

    if (paymentData) {
      clientSecret = paymentData.client_secret;
    }
    else {
      clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      paymentId = new URLSearchParams(window.location.search).get(
        "payment_intent"
      );

      if (!clientSecret) {
        return;
      }
    }

    if (clientSecret && paymentId) {
      //console.log("ENTRO")
      stripe.retrievePaymentIntent(clientSecret).then((paymentIntent: PaymentIntentResult) => {
        //console.log("RETRIEVE PAYMENT: ", paymentIntent);
        switch (paymentIntent.paymentIntent?.status) {
          case "succeeded":
            paidApi({ clientSecret, paymentId });
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }


  }, [stripe, elements, paymentData, jwt]);

  const handleCardChange = (event: any) => {
    // setIsCardComplete(event.complete);
    // console.log("Card data: ", event);
    setFormComplete(event?.complete);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      //console.log("RETURN")
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    console.log('Stripe: ', stripe,'Elements: ', elements, 'Email: ', email);
    
    if (email) {

      setIsLoading(true);

      stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:3000/inquiry/" + inquiryId,
        },
        redirect: 'if_required'
      }).then((result) => {
        

        if (result?.error) {
          setMessage(result.error.message ? result.error.message : "An error has ocurred");
        } else {
          paidApi({ clientSecret: result.paymentIntent.client_secret, paymentId: result.paymentIntent.id, inquiryId, certificateId });
        }
      }).catch((error) => {
        //console.log("some error, ", error);
        setIsLoading(false);
      })

      
    }
  };

  const paidApi = async (body: any) => {

    try {
      const paid = await PaymentService.paid(body, jwt); //resp?.data?.result?.status;
      if (paid?.status === 'succeeded') {
        setCorrectPayment(true)
        setModalPayment(false);
        setInquiryId(inquiryId);
        setIsLoading(false);
        dispatch(setInquiryConfirmed(inquiryId ? inquiryId : ''));
        // console.log('ADD CREDITS AVAI BEF: ', paid?.credits);
        dispatch(addCreditsAvailable(paid?.credits));
        // console.log('ADD CREDITS AVAI AFT: ', paid?.credits);
        // console.log('ADD CREDITS CONSUM BEF: ', credits);
        // TODO if negado payment
        if (!isPayingInquiry) dispatch(addCreditsConsumed(credits));
        // console.log('ADD CREDITS CONSUM AFT: ', credits);
      }
    } catch (error: any) {
      console.error('Error captured:', error);
      return undefined;
    }

  }

  return (
    <>
      <Form id="payment-form" onSubmit={handleSubmit}>
        {message !== 'Payment succeeded!' && (
          <>
            <LinkAuthenticationElement
              id="link-authentication-element"
              onChange={(e: any) => setEmail(e.value?.email)}
            />
                <PaymentElement id="payment-element" options={{ layout: "accordion" }} onChange={handleCardChange} />
                {message && <div className="mt-3" id="payment-message">{message}</div>}
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <Button className="mt-5 p-3" disabled={isLoading || !stripe || !elements || email === '' || formComplete === false} type="submit">
                    <span id="button-text ">
                      {t('components.checkoutForm.paymentButton')}
                    </span>
                  </Button>
              </>
            )}
          </>
        )}
        {/* Show any error or success messages */}

      </Form>

    </>
  );
}
