
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { USER_TYPE, logout, selectUserInfo } from "../features/user/user";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTransition } from "react";
// import { useState } from "react";

function Header() {


	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userInfo = useSelector(selectUserInfo);
	const { i18n } = useTranslation('common');
	const { t } = useTranslation('common');

	const changeLang = () => {
		console.log("CAMBIO IDIOMA")
        i18n.changeLanguage(i18n.language  === 'es' ? 'en' : 'es' );
    }
	// const [showNotifications, setShowNotifications] = useState(false);

	const switchTheme = () => {
		if (document.documentElement.getAttribute('data-bs-theme') === 'dark') {
			document.documentElement.setAttribute('data-bs-theme', 'light')
		}
		else {
			document.documentElement.setAttribute('data-bs-theme', 'dark')
		}
	}
	const getURLConditions = () : string => {
		return t('components.header.urlConditions') ? t('components.header.urlConditions') : '/';
	}

	const getURLFaqs = () : string => {
		return t('components.header.urlFaqs') ? t('components.header.urlFaqs') : '/';
	}
	// min-width: '280px'
	return (
		<div className="col-auto m-0 p-0">
			<nav className="d-flex align-items-start flex-column vh-100 text-light-emphasis bg-light-subtle px-1" style={{ minWidth: '280px', width: '280px', overflow: 'overlay' }} data-bs-theme="dark">

				<div className="mb-auto container-fluid">
					<div className="pt-3 d-flex justify-content-between align-items-center">
						<a className="navbar-brand" href="/">
							<img src="./assets/img/bswhite.png" style={{maxWidth : '30px'}} alt="logo" />
						</a>
						<button className="ml-2 btn btn-outline-primary text-nowrap" onClick={changeLang}>{i18n.language.toUpperCase()}</button>
						<span className="nav-item" onClick={switchTheme} id="btnSwitch">
							<i className="bi bi-sun icon-lg"></i>
							<i className="bi bi-moon icon-lg"></i>
						</span>
						{/* <li className="dropdown leng-drop " onClick={ (e) => e.preventDefault()}>
							<div className="nav-link dropdown-toggle dd-item-lenguage">{i18n.language.toUpperCase()}</div>
							<ul className="dropdown-menu signledropdown lenguage-style">
								<li><div className="dd-item" onClick={ () => changeLang('en')}>EN</div></li>
								<li><div className="dd-item" onClick={() => changeLang('es')}>ES</div></li>
							</ul>
						</li> */}
					</div>
					<hr />
					<ul className="navbar-nav mb-2 ps-1">
						<li className="nav-item" >
							<Link to="/balance" className="nav-link w-100">
								<i className="bi bi-wallet2 icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.balance')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
							{/* <a className="nav-link active w-100" aria-current="page" >
							<i className="bi bi-wallet2 icon-lg"></i><span className="align-middle-lg ms-3">Balance XXX</span>
							<i className="bi bi-chevron-right icon-lg float-end"></i>
						</a> */}
						</li>
						<li className="nav-item">
							<Link to="/inquiry" className="nav-link w-100">
								<i className="bi bi-search icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.inquiry')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/historic" className="nav-link w-100">
								<i className="bi bi-clock-history icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.historic')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
						</li>
						{userInfo.userType === USER_TYPE.ADMIN &&(<li className="nav-item">
							<Link to="/tokens" className="nav-link w-100">
								<i className="bi bi-coin icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.tokens')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
							<Link to="/users" className="nav-link w-100">
								<i className="bi bi-people icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.users')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
						</li>)}
						<li className="nav-item">
							<Link to="/plans" className="nav-link w-100">
								<i className="bi bi-currency-dollar icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.buyCredits')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/profile" className="nav-link w-100">
								<i className="bi bi-gear icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.configuration')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</Link>
						</li>
					</ul>
					{/* <div className="accordion" id="accordion_notifications">
					<div className="accordion-item text-light-emphasis bg-info-subtle">
						<h2 className="accordion-header" id="notifications">
							<button className={showNotifications ? "accordion-button text-light-emphasis bg-info-subtle" : "accordion-button text-light-emphasis bg-info-subtle collapsed"} onClick={() => {setShowNotifications(!showNotifications)}} type="button" 
									data-bs-toggle="collapse" aria-expanded={showNotifications ? "true" : "false"} aria-controls="collapseOne" >
								<small><strong>NOTIFICACIONES</strong></small>
								<span className="badge text-bg-danger ms-3">3</span>
							</button>
						</h2>
						<div id="collapse_notifications" className={showNotifications ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="notifications" data-bs-parent="#accordion_notifications">
							<div className="accordion-body">
								<ul className="nav flex-column">
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div> */}
				</div>
				<div className="container-fluid">

					{/* <a className="nav-link w-100" aria-current="page" href="/">Condiciones legales</a> */}
					{/* <hr /> */}
					<ul className="navbar-nav mb-2 ps-1">
						<li className="nav-item">
							<a className="nav-link w-100" aria-current="page" href={getURLFaqs()} target="_blank" >
								<i className="bi bi-question-circle icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.faqs')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link w-100" aria-current="page" href={getURLConditions()} target="_blank">
								<i className="bi bi-life-preserver icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.conditions')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</a>
						</li>
					</ul>
					<hr />
					<ul className="navbar-nav mb-2 ps-1">
						<li className="nav-item" onClick={() => { dispatch(logout()) }}>
							<a className="nav-link w-100" aria-current="page" href="/">
								<i className="bi bi-box-arrow-right icon-lg"></i><span className="align-middle-lg ms-3">{t('components.header.logOut')}</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	)
}

export default Header;