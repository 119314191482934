import { useSelector } from "react-redux";
import { selectUserInfo } from "../features/user/user";
import { BLOCKCHAIN, Token } from "../models/models";
import TypeTokenRow from "./TypeTokenRow";

type RowTableProps = {
    index: number
    balance : any;
    token : Token;
    iconBlockchain : any;
}

const getUrl = (token: Token, wallet?: string) : string  => {

    const address = wallet ? wallet : token.address;

    return address ? 
        token.blockchain === BLOCKCHAIN.ETHEREUM ? 'https://etherscan.io/address/' + address :
        token.blockchain === BLOCKCHAIN.POLYGON ? 'https://polygonscan.com/address/' + address :
        'https://bscscan.com/address/'+ address : '';

}

function RowTokenTable({index, balance, token , iconBlockchain }: RowTableProps) {


    const userInfo = useSelector(selectUserInfo);

    return (
        <>
            <td className="">
                {balance.wallet.length > 20
                ? `${balance.wallet.slice(0, 6)}...${balance.wallet.slice(-4)}`
                : balance.wallet} &nbsp;&nbsp; 
                {balance.wallet && (<i className="bi bi-box-arrow-up-right pointer" onClick={ () => window.open(getUrl(token,balance.wallet),'_blank')}></i>)}&nbsp;&nbsp; 
                {balance.wallet && (<i className="bi bi-clipboard-check-fill pointer" onClick={ () => navigator.clipboard.writeText(getUrl(token,balance.wallet))}></i>)}
            </td>
            <td className="">
                <span className="btn-image-md">
                    {/* {index}) - */}
                    <img className="m-1" src={iconBlockchain(token?.blockchain)} alt="Imagen" />
                </span>
            </td>
            <td className="" style={{maxWidth : '50px'}}>
                {token.symbol}
            </td>
            <td className="" style={{maxWidth : '50px'}}>
                {token.name}
            </td>
            <td className="" style={{maxWidth : '50px'}}>
                {token.address && token.address.length > 20
                    ? `${token.address?.slice(0, 6)}. . .${token.address?.slice(-4)}`
                    : '-'}&nbsp;&nbsp; 
                {token.address && (<i className="bi bi-box-arrow-up-right pointer" onClick={ () => window.open(getUrl(token),'_blank')}></i>)}&nbsp;&nbsp; 
                {token.address && (<i className="bi bi-clipboard-check-fill pointer" onClick={ () => navigator.clipboard.writeText(getUrl(token,balance.wallet))}></i>)}
            </td>
            <TypeTokenRow id={token._id} trustLevel={token.trustLevel} userType={userInfo.userType} setIdToken={() => {}} setShowTokenModal={ () => {}}></TypeTokenRow>
            <td className="" style={{textAlign: 'end'}}>
                {token.balance ? token.balance.toFixed(6) : '-'}
            </td>
            <td className="" style={{textAlign: 'end'}}>
                {token.balanceFiat ? String(token.balanceFiat?.toFixed(6) + ' $')  : '-'}&nbsp;
            </td>
        </>
    )
}

export default RowTokenTable;