import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WalletsData from "../components/walletsData";
import { modifyUserType, selectJWT, selectUserInfo, selectUsers, setError, setSuccess, setUsers, USER_TYPE, UserInfo } from "../features/user/user";
import { useAppDispatch } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BalanceLoader from "../loaders/BalanceLoader";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import { AdminService } from "../services/admin.service";


function Users() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const jwt = useSelector(selectJWT);
    const userData = useSelector(selectUserInfo);
    const usersStore = useSelector(selectUsers);
    const [users, setUsersLocal] = useState<UserInfo[]>([]);
    const [loading, setLoading] = useState(true);
    const [showModalUserType, setShowModalUserType] = useState(false);
    const [userEdit, setUserEdit] = useState<UserInfo>();
    const [showChecks, setShowChecks] = useState<boolean>(true);

    const getUserType = (userType?: USER_TYPE) => {
        return userType === USER_TYPE.FREE ? 'Usuario' : userType === USER_TYPE.ADMIN ? 'Admin' : 'Empresa';
    }

    const saveUserType = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        try {
            if (userEdit) {
                const target = event.target as typeof event.target & {
                    userType: { value: USER_TYPE };
                    alias: { value: string }
                };

                const user = { _id: userEdit._id, userType: Number(target.userType.value), alias: target.alias.value };

                await AdminService.saveUserData(user, jwt);
                dispatch(modifyUserType(user));
                dispatch(setSuccess({ title: "Save data", desc: "Profile data saved correctly" }));
                setShowModalUserType(false);
                return;
            }
            else {
                throw new Error("Error saving data");
            }

        } catch (error) {
            setLoading(false);
            console.error('Error save user type', error);
            dispatch(setError({ title: 'User error', desc: 'Error save user type' }))
            setShowModalUserType(false);
        }


    }

    useEffect(() => {

        const callUsers = async () => {

            try {
                const users: UserInfo[] = await AdminService.getUsers(jwt);
                dispatch(setUsers(users))
                setUsersLocal(users);
                setLoading(false)
            } catch (error: any) {
                setLoading(false);
                console.error('Error get users', error);
                dispatch(setError({ title: 'Users error', desc: 'Error has ocurred trying get users' }))
            }

        }

        if (userData.userType !== USER_TYPE.ADMIN) {
            navigate('/balance');
        }

        if (usersStore?.length > 0) {
            setLoading(false);
            setUsersLocal(usersStore)
        }
        else {
            callUsers();
        }


    }, [jwt, usersStore, userData, dispatch, navigate]);

    return (<>
        <div id="dashboard" className="d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto" style={{ width: "calc(100% - 280px)" }}>
            <WalletsData setInquiryId={() => { }} />
            <div className="row px-1 py-4 rounded-top-4 mt-auto pt-5 pb-5 text-light-emphasis bg-light-subtle">
                {/* <div className="row row-gap-3 mt-5 mb-5"> </div> */}
                <div className="col-lg-12 col-md-8 mt-4 d-flex justify-content-start">
                    <label className={"m-2 btn btn-primary ps-3 align-middle-lg"}
                        onClick={() => { setShowChecks(!showChecks) }} >
                        <span className="btn-image-md">

                        </span>
                        {t('views.users.vinculateUsers')}
                    </label>
                </div>
            </div>
            {loading && (<BalanceLoader />)}
            {!loading && (
                <table className="table table-striped table-hover table-borderless align-middle">
                    <thead className="sticky-top">
                        <tr className="text-light-emphasis">
                            <th scope="col">{t('views.users.ids')}</th>
                            <th scope="col">{t('views.users.name')}</th>
                            <th scope="col">{t('views.users.time')}</th>
                            <th scope="col">{t('views.users.wallet')}</th>
                            <th scope="col">{t('views.users.userType')}</th>
                            {/* <th scope="col">{t('views.users.edit')}</th> */}
                            {/* <th scope="col">{t('views.users.mainAsset')}</th>
                            <th scope="col">{t('views.users.trustLevel')}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user: UserInfo, index: number) => (

                            user.wallets?.map((wallet: any, indexWallet: number) => (
                                <tr key={index + "_" + indexWallet}>
                                    <td className="text-nowrap">
                                        {showChecks && (
                                            <div className="btn-group">
                                                <Form.Check
                                                    className=""
                                                    type="checkbox"
                                                    id="default-checkbox"
                                                    label=""
                                                />
                                            </div>
                                        )}
                                        <div className="btn-group pointer" role="group" aria-label="User" onClick={() => { console.log("CLICK"); setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;

                                            <button className="btn btn-outline-primary pe-none" >
                                                <i className="bi bi-pencil-square "></i>
                                            </button>
                                        </div>
                                        <span className="btn-image-md">
                                            <span className="ms-2">
                                                {user.alias ? user.alias : user._id}
                                            </span>
                                        </span>

                                    </td>
                                    <td className="text-nowrap">
                                        <span className="btn-image-md">
                                            <span className="ms-2">
                                                {user.userData?.name ? user.userData.name : 'Without name'}&nbsp;
                                                {user.userData?.lastName ? user.userData.lastName : ''}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="text-nowrap">
                                        <span className="btn-image-md">
                                            <span className="ms-2">{user.userData?.timeZoneDefault ? user.userData.timeZoneDefault : 'Time zone not choosen'}</span>
                                        </span>
                                    </td>
                                    <td className="text-nowrap">
                                        <span className="btn-image-md">
                                            <span className="ms-2">{wallet.wallet}</span>
                                        </span>
                                    </td>
                                    <td className="text-nowrap">
                                        <div className="btn-group pointer" role="group" aria-label="User" onClick={() => { setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;
                                            <button type="button" className="btn btn-outline-primary pe-none">
                                                <i className="bi bi-pencil-square "></i>
                                            </button>
                                        </div>
                                        <span className="btn-image-md">
                                            <span className="ms-2">{getUserType(user.userType)}</span>
                                        </span>
                                    </td>
                                </tr>
                            ))


                        ))}

                    </tbody>
                </table>
            )}
        </div>
        <Modal
            onHide={() => { setShowModalUserType(false) }}
            show={showModalUserType}
            size="sm"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('views.users.userInfo')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form className="row d-flex justify-content-center m-2" onSubmit={saveUserType}>
                    {userEdit && (
                        <>
                            <div className="col-12 form-floating">
                                <input type="text" className="form-control  rounded-top-2" id="alias" defaultValue={userEdit.alias} required />
                                <label>{t('views.users.alias')}</label>
                            </div>
                            <Form.Group className="col-12 mt-3" controlId="userType">
                                <Form.Label>{t('views.users.setUserType')}</Form.Label>
                                <Form.Select defaultValue={getUserType(userEdit.userType)} aria-label={getUserType(userEdit.userType)}>
                                    <option value={String(userEdit.userType)}>{getUserType(userEdit.userType)}</option>
                                    {userEdit.userType !== USER_TYPE.FREE && (<option value="0">{t('views.users.user')}</option>)}
                                    {userEdit.userType !== USER_TYPE.ENTERPRISE && (<option value="2">{t('views.users.enterprise')}</option>)}
                                    {userEdit.userType !== USER_TYPE.ADMIN && (<option value="1">{t('views.users.admin')}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </>
                    )}
                    <Button className="mt-4" variant="primary" type="submit">
                        {t('views.users.saveType')}
                    </Button>
                    {loading && (
                        <div className='col-lg-12 m-2'>
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="secondary" />
                        </div>
                    )}
                </Form>
            </Modal.Body>
        </Modal>

    </>)
}

export default Users;
