import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadState } from '../../app/sessionHelper';
import { RootState } from '../../app/store';
import { Certificate, CurrentBalance, Inquiry, ModifyToken, Plan, Token } from '../../models/models';
// import { fetchCount } from './userAPI';

export function fetchCount(amount = 1) {
    return new Promise<{ data: number }>((resolve) =>
      setTimeout(() => resolve({ data: amount }), 500)
    );
  }

export enum USER_TYPE {
  FREE,
  ADMIN,
  ENTERPRISE
}

export type WalletInfo = {
  nonce? : string,
  wallet?: string,
  _id?: string
}

export type UserData = {
  _id?: string;
  name?: string,
  lastName?: string,
  email? : string, 
  phone?: string,
  timeZoneDefault?: string,
  userType?: USER_TYPE,
  alias?: string
}

export type InvoiceData = {
  name?: string,
  businessName?: string,
  cif? : string, 
  address?: string,
  zip?: number
}

export type UserInfo = {
  _id?: string;
  email?: string,
  name?: string,
  url?: string,
  img? : string,
  banner? : string
  userType?: USER_TYPE;
  wallets? : string[] | any[];
  creditsTotal? : number;
  creditsConsumed? : number;
  creditsAvailable? : number;
  newUser?: boolean;
  userData?: UserData;
  invoiceData?: InvoiceData;
  alias? : string
}

export type ResponseWalletAdded = {
  user: UserInfo,
  wallets : string[]
}
export type Detail = {
  title?: string;
  desc?: string;
}

export interface UserState {
  wallet: string;
  status: 'ok' | 'ko';
  jwt: string;
  userInfo: UserInfo;
  tokens : Token[];
  users: UserInfo[];
  balances : CurrentBalance[];
  totalFiat : number;
  historic : Inquiry[];
  plans : Plan[];
  error? : Detail;
  notification?: Detail;
  success? : Detail;
}

const initialState: UserState =  loadState();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWallet: (state, action: PayloadAction<string>) => {
      state.wallet = action.payload;
      },
    setTotalFiat: (state, action: PayloadAction<number>) => {
      state.totalFiat = action.payload;
      },
    setJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userInfo.userData = action.payload;
    },
    setUsers: (state, action: PayloadAction<UserInfo[]>) => {
      state.users = [...action.payload];
    },
    setTimeZone: (state, action: PayloadAction<string>) => {
      if(state.userInfo.userData){
        state.userInfo.userData.timeZoneDefault = action.payload;
      }
    },
    setInvoiceData: (state, action: PayloadAction<InvoiceData>) => {
      state.userInfo.invoiceData = action.payload;
    },
    setBalances: (state, action: PayloadAction<CurrentBalance[]>) => {
      state.balances = [...action.payload];
    },
    setTokens: (state, action: PayloadAction<Token[]>) => {
      state.tokens = [...action.payload];
    },
    modifyToken: (state, action: PayloadAction<ModifyToken>) => {
      state.tokens.forEach( (token : Token) => {
        if(token._id === action.payload._id){
          token.trustLevel = action.payload.trustLevel;
        }
      })
    },
    setHistoric : (state, action: PayloadAction<Inquiry[]>) => {
      state.historic = [...action.payload];
    },
    setPlans : (state, action: PayloadAction<Plan[]>) => {
      state.plans = [...action.payload];
    },
    setNewPlan : (state, action: PayloadAction<Plan[]>) => {
      state.plans = state.plans.concat(action.payload);
    },
    setNewInquiry : (state, action: PayloadAction<Inquiry>) => {
      state.historic = state.historic.concat([action.payload]);
    },
    modifyUserType: (state, action: PayloadAction<UserData>) => {
      state.users.forEach( user => {
        if(user._id === action.payload._id){
          user.userType = Number(action.payload.userType);
          user.alias = action.payload.alias;
        }
      })
    },
    setNewCertificate : (state, action: PayloadAction<Certificate>) => {
      state.historic.forEach( inquiry => {
        if(inquiry._id === action.payload.inquiryId){
          inquiry.certificate = action.payload;
        }
      })
    },
    setInquiryConfirmed : (state, action: PayloadAction<string>) => {
      state.historic.forEach( (inquiry : Inquiry) => {if(inquiry._id === action.payload) inquiry.consumed = true});
    },
    addCreditsConsumed: (state, action: PayloadAction<number>) => {
      state.userInfo.creditsConsumed = state.userInfo.creditsConsumed ? state.userInfo.creditsConsumed + action.payload : action.payload; // ¿Seguro que debe funcionar así?
      // state.userInfo.creditsTotal = state.userInfo.creditsTotal ? state.userInfo.creditsTotal + action.payload : action.payload;
      state.userInfo.creditsAvailable = state.userInfo.creditsAvailable ? state.userInfo.creditsAvailable - action.payload : action.payload;
    },
    addCreditsAvailable: (state, action: PayloadAction<number>) => {
      state.userInfo.creditsAvailable = state.userInfo.creditsAvailable ? state.userInfo.creditsAvailable + action.payload : action.payload;
    },
    addWalletToUser: (state, action: PayloadAction<string>) => {
      if(!state.userInfo.wallets){
        state.userInfo.wallets = [state.wallet];
      }
      state.userInfo.wallets.push(action.payload);
    },
    setError: (state, action: PayloadAction<Detail>) => {
      state.error = action.payload;
    },
    setSuccess: (state, action: PayloadAction<Detail>) => {
      state.success = action.payload;
    },
    setNotification: (state, action: PayloadAction<Detail>) => {
        state.notification = action.payload;
    },
    cleanError: (state, action: PayloadAction<void>) => {
      state.error = undefined;
    },
    cleanSuccess: (state, action: PayloadAction<void>) => {
      state.success = undefined;
    },
    cleanNotification: (state, action: PayloadAction<void>) => {
      state.notification = undefined;
    },
    logout: (state, action: PayloadAction<void>) => {
      sessionStorage.removeItem('userState');
      return {  wallet : '', jwt : '', status : 'ko', userInfo : {email : ''}, totalFiat : 0, error : undefined, notification : undefined, balances : [], historic : [], plans : [], tokens : [], users : [], alias : ''};
    }
  }
});

export const { setWallet, setJWT, setUserInfo,setUserData,setTokens,modifyToken, setTotalFiat, setUsers, setTimeZone, setInvoiceData,setNewCertificate,setSuccess, setNewInquiry, setInquiryConfirmed,setPlans, setNewPlan, addWalletToUser,setError,setNotification,cleanError,cleanNotification,cleanSuccess,logout, setBalances, addCreditsConsumed,setHistoric,addCreditsAvailable,modifyUserType} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.user.value)`
export const selectWallet = (state: RootState) => state.user.wallet;
export const selectJWT = (state: RootState) => state.user.jwt;
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectError = (state: RootState) => state.user.error;
export const selectSuccess = (state: RootState) => state.user.success;
export const selectNotification = (state: RootState) => state.user.notification;
export const selectBalances = (state: RootState) => state.user.balances;
export const selectHistoric = (state: RootState) => state.user.historic;
export const selectPlans = (state: RootState) => state.user.plans;
export const selectTokens = (state: RootState) => state.user.tokens;
export const selectUsers = (state: RootState) => state.user.users;
export const selectFiat = (state: RootState) => state.user.totalFiat;


export default userSlice.reducer;
