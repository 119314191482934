import { useSelector } from "react-redux";
import { UserData, selectJWT, selectUserInfo, setError, setSuccess, setTimeZone, setUserData } from "../features/user/user";
import { UserService } from "../services/user.service";
import { useAppDispatch } from "../app/hooks";
import TimezoneSelect, { ITimezoneOption } from "react-timezone-select";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type FormProfileProps = {
    showSaveButton: boolean,
    showSaveData: boolean,
    userData?: any,
    setUserDataLocal?: any,
    showTimeZone? : boolean
}

function FormProfile({ showSaveButton = false, showSaveData = false, showTimeZone = false, userData, setUserDataLocal }: FormProfileProps) {

    const userInfo = useSelector(selectUserInfo);
    const jwt = useSelector(selectJWT);
    const dispatch = useAppDispatch();
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezoneOption>({ value: '', label: '', offset : 0 });
    const { t } = useTranslation('common');

    const saveProfileData = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            name: { value: string };
            lastName: { value: string };
            email: { value: string };
        };

        const data: UserData = {
            name: target.name.value,
            lastName: target.lastName.value,
            email: target.email.value,
            timeZoneDefault : userInfo.userData?.timeZoneDefault
        }
        
        if(showTimeZone && selectedTimezone.label){
            data.timeZoneDefault = selectedTimezone.label;
            dispatch(setTimeZone(selectedTimezone.label));
        }

        try {

            await UserService.saveProfileData(data, jwt);
            dispatch(setUserData(data));
            dispatch(setSuccess({ title: "Save data", desc: "Profile data saved correctly" }));
        } catch (error) {
            dispatch(setError({ title: 'Save data error', desc: 'Error has ocurred trying save your profile data' }));

        }
    }

    return (
        <form className="mb-4 d-grid gap-3 text-start needs-validation" onSubmit={saveProfileData} >
            <h3 className="h5">{t('components.formProfile.userInfo')}</h3>
            <div className="form-floating">
                <input type="text" className="form-control" id="floatingNameInput" name="name"
                    defaultValue={userInfo.userData?.name ? userInfo.userData.name : ''}
                    onChange={(event: any) => { setUserDataLocal({ ...userData, name: event.target.value }) }}
                     pattern="^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$" />
                <label >{t('components.formProfile.name')}</label>
                <div className="invalid-feedback">{t('components.formProfile.letName')}</div>
            </div>
            <div className="form-floating">
                <input type="tel" className="form-control" id="floatingPhoneInput" name="lastName"
                    defaultValue={userInfo.userData?.lastName ? userInfo.userData.lastName : ''}
                    onChange={(event: any) => { setUserDataLocal({ ...userData, lastName: event.target.value }) }}
                     pattern="^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$" />
                <label >{t('components.formProfile.lastname')}</label>
                <div className="invalid-feedback">{t('components.formProfile.letLastname')}</div>
            </div>
            {/* <div className="form-floating">
                <input type="tel" className="form-control" id="floatingPhoneInput" name="phone" placeholder="555-1234" required pattern="^\d{3}-\d{4}$" />
                <label >Teléfono</label>
                <div className="invalid-feedback">Por favor ingrese un nombre válido</div>
            </div> */}
            <div className="form-floating">
                <input type="email" className="form-control" id="floatingEmailInput" name="email"
                    defaultValue={userInfo.userData?.email ? userInfo.userData.email : ''}
                    onChange={(event: any) => { setUserDataLocal({ ...userData, email: event.target.value }) }}
                    placeholder="name@example.com" />
                <label >{t('components.formProfile.email')}</label>
                <div className="invalid-feedback">{t('components.formProfile.letEmail')}</div>
            </div>
            {showTimeZone && (<div className="form-floating">
                {/* <label className="form-label">Time Zone</label> */}
                {/* <select className="form-select " id="validationDefault04" required>
						<option selected disabled value="">Choose Country</option>
						<option value="GMT8">(GMT+8:00) Kuala Lumpur, Singapore</option>
						<option>...</option>
					</select> */}
                <TimezoneSelect
                    className=""
                    value={selectedTimezone}
                    defaultInputValue={userInfo.userData?.timeZoneDefault}
                    // defaultValue={{ value: userInfo.userData?.timeZoneDefault, label: userInfo.userData?.timeZoneDefault }}
                    onChange={setSelectedTimezone}
                />
            </div>)}
            <div className="form-check">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <input className="form-check-input" type="checkbox" value="" id="legalCheck" name="legal" checked={true} onChange={(event) => { setUserDataLocal({ ...userData, correctData: true }) }} />
                    </div>
                    <div className="col-auto m-0 p-0">
                        <label className="form-check-label" >
                            {t('components.formProfile.checkInfo')}
                        </label>
                    </div>
                </div>
                {showSaveData && (<div className="row align-items-center">
                    <div className="col-auto">
                        <input className="form-check-input" type="checkbox" value="" id="legalCheck" name="legal" onChange={(event) => { setUserDataLocal({ ...userData, saveData: true }) }} required />
                    </div>
                    <div className="col-auto m-0 p-0">
                        <label className="form-check-label" >
                            {t('components.formProfile.nextPurchases')}
                        </label>
                    </div>
                </div>)}
            </div>
            
            {showSaveButton && (<div className="ms-auto">
                <button type="submit" className="btn btn-primary">{t('components.formProfile.save')}</button>
            </div>)}
        </form>
    )
}

export default FormProfile;