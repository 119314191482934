import { ethers } from 'ethers';
import { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../app/hooks';
import { selectJWT, setJWT, setUserInfo, setWallet, setError, addCreditsConsumed, setSuccess } from '../features/user/user';
import { SignInResponse, SignUpResponse } from '../models/models';
import AnalyticsService from "../services/analytics.service";
import LoginService from '../services/login.service';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Login() {

    const loginService = new LoginService();
    // const [error, setError] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);
    const dispatch = useAppDispatch();
    const jwt = useSelector(selectJWT);
    const [walletUser, setWalletUser] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const connectMetamask = async () => {

        AnalyticsService.click('Login with Metamask');
        let reqAcc;
        try {

            const provider = new ethers.BrowserProvider((window as any).ethereum);
            reqAcc = await provider.send('eth_requestAccounts', []);
        } catch ({ code }: any) {
            switch (code) {
                case -32002:
                    dispatch(setError({ title: "Error Metamask", desc: "Pending Metamask connection. Please check." }));
                    break;
                default:
                    dispatch(setError({ title: "Error Metamask", desc: "Error trying Connect Metamask" }));

            }
            AnalyticsService.error('Connecting Metamask');
        }
        try {
            if (reqAcc) {
                const user = await loginService.findUserByWalletId(reqAcc[0]);

                if (user) {
                    signIn(reqAcc[0], user.nonce);
                } else {
                    setShowSignUp(true);
                    setWalletUser(reqAcc[0]);
                }
            } else {
                dispatch(setError({ title: "Error Metamask", desc: "Error trying Connect Metamask" }));
                AnalyticsService.error('Connecting Metamask');
            }

        } catch (error: any) {
            dispatch(setError({ title: "Error Metamask", desc: error?.info?.error?.code ? error.info.error.message : error?.info?.error?.code ? error.info.error.message : "There was an error. Please try again in some minutes." }));
            AnalyticsService.error('Connecting API');
        }

    };

    const handleForm = async (e: React.SyntheticEvent) => {
        setLoading(true);
        AnalyticsService.click("Sign up");
        e.preventDefault();
        await signUp(walletUser ? walletUser : '', 'email@prueba.es');
    }


    const signUp = async (userWallet: string, email: string) => {

        AnalyticsService.click('SigUp User');
        try {
            // setEmail(email);
            const signUpResponse: SignUpResponse = await loginService.signUp(userWallet, email);
            //console.log("SIGNUP DONE: ", signUpResponse);
            await signIn(userWallet, signUpResponse.nonce, true)
            setLoading(false);
            setShowSignUp(false);
        } catch (error: any) {
            AnalyticsService.error('SigUp User');
            dispatch(setError({ title: "Error Metamask", desc: error?.info?.error?.code ? error.info.error.message : "There was an error. Please try again in some minutes." }));
        }

    }

    const signIn = async (wallet: string, nonce: string, newUser? : boolean) => {

        AnalyticsService.click('SigIn User ' + wallet);
        try {
            const signature = await signMessage(nonce);
            if (signature) {
                const loginResponse: SignInResponse = await loginService.signIn(wallet, signature);
                //console.log("SIGNIN DONE: ", loginResponse);
                dispatch(setWallet(wallet));
                dispatch(setJWT(loginResponse.accessToken));
                dispatch(setUserInfo({...loginResponse.userInfo, newUser }));
                if(newUser){
                    dispatch(setSuccess({ title : 'You have a gift!', desc : 'Welcome to BalanceShot!. You receive 3 credits for get inquiries'}));
                    dispatch(addCreditsConsumed(3)); 
                }
                  
                navigate("/balance"); 
            }
            else {
                dispatch(setError({ title: "Error Metamask", desc: "Error trying to sign message" }));
            }
        } catch (error: any) {
            console.error(error);
            dispatch(setError({ title: "Error Metamask", desc: error?.info?.error?.code ? error.info.error.message : "Error trying Connect Metamask" }));
            AnalyticsService.error('SigIn User');
        }

    }

    const signMessage = async (nonce: string) => {
        const provider = new ethers.BrowserProvider((window as any).ethereum);
        const signer = await provider.getSigner();
        const signature = await signer.signMessage(`Login on balanceShot with nonce: ${nonce}`);
        return signature;
    }



    // const createWallet = () =>{
    //     let randomWallet = ethers.Wallet.createRandom();
    //     //console.log(randomWallet);
    // }

    return (
        <div className="vw-100 vh-100 p-0 m-0 bkg-login" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className='container'>
                {!jwt && !showSignUp && (
                    <div className="modal fade show" id="inicioModal" data-bs-backdrop="static" data-bs-keyboard="false" style={{ display: 'block' }} aria-labelledby="inicioModalLabel" aria-hidden="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="container">
                                    <div className="p-3 my-4 text-center">
                                        <img className="mb-4" src="./assets/img/blackAlpha.png" style={{maxWidth: '20%'}} alt="logo-login" />
                                        <h1 className="h3">{t('views.login.welcome')}</h1>
                                        <p>{t('views.login.login')}</p>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={connectMetamask}>
                                            <img src="./assets/img/metamask.png" alt="logo-metamask" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <Modal
                    onHide={() => { setShowSignUp(false) }}
                    show={showSignUp}
                    size="lg"
                    centered
                >

                    {!loading ? (<div className="modal-content">
                        <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowSignUp(false)}></button>
                        <div className="container">
                            <div className="p-3 my-4 text-center">
                                <img className="mb-4" src="./assets/img/logoBS.png" alt="logo-signup" />
                                <h1 className="h2">{t('views.login.register')}</h1>
                                <form className="mt-4 d-grid gap-3 text-start needs-validation" onSubmit={handleForm}>
                                    <div className="form-floating">
                                        <input type="email" className="form-control" id="floatingEmailInput" placeholder="name@example.com" />
                                        <label>{t('views.login.email')}</label>
                                    </div>
                                    <div className="invalid-feedback">{t('views.login.validEmail')}</div>
                                    <div className="form-check">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <input className="form-check-input" type="checkbox" value="" id="legalDosCheck" required />
                                            </div>
                                            <div className="col-auto m-0 p-0">
                                                <label className="form-check-label">
                                                    {t('views.login.acceptPolicies')}<br />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        {t('views.login.signUp')}  
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>) :
                        <div className="container">
                            <div className="p-5 m-5 text-center">
                                <Spinner className='m-5' />
                            </div>
                        </div>
                    }
                </Modal>
            </div>
        </div>
    );
}

export default Login;
