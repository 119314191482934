import { useSelector } from "react-redux";
import { selectJWT, setError, selectBalances, setBalances, setTotalFiat, selectFiat } from "../features/user/user";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import WalletsData from "../components/walletsData";
import BalanceService from "../services/balance.service";
import { CurrentBalance, Balance as BalanceDetail, Token, BLOCKCHAIN } from "../models/models";
import Filters from "../components/Filters";
import RowTokenTable from "../components/RowTokenTable";
import BalanceLoader from "../loaders/BalanceLoader";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";


function Balance() {

    const dispatch = useAppDispatch();
    const balancesStore = useSelector(selectBalances);
    const valFiat = useSelector(selectFiat);
    const jwt = useSelector(selectJWT);
    const [balances, setBalancesData] = useState<CurrentBalance[]>([]);
    const [filtered, setFiltered] = useState<CurrentBalance[]>([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation('common');
    const [balanceFiat, setBalanceFiat] = useState(0);
    const [enableAllFilters, setEnableAllFilters] = useState(false);

    useEffect(() => {

        const callBalance = async () => {

            try {
                const balances: CurrentBalance[] = await BalanceService.currentBalance(jwt);
                setLoading(false);
                setBalancesData(balances);
                let valFiat = 0;
                balances.forEach( bal => { bal.balance.forEach( balance => {  
                    balance?.tokens.forEach( (token : Token) => {
                        if(token.balanceFiat) valFiat += token.balanceFiat;
                    })
                    })  
                })
                setBalanceFiat(valFiat);
                dispatch(setTotalFiat(valFiat))
                dispatch(setBalances(balances));
            } catch (error: any) {
                setLoading(false);
                console.error('Error get current balance:', error);
                dispatch(setError({ title: 'Balance error', desc: 'Error has ocurred trying get your balance' }))
            }

        }

        if (balancesStore?.length > 0) {
            setLoading(false);
            setBalancesData(balancesStore)
        }
        else {
            callBalance();
        }

        if(valFiat){
            setBalanceFiat(valFiat);
        }
    }, [jwt, balancesStore, dispatch,valFiat]);

    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }


    return (
        <>
            <div id="dashboard" className="d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto" style={{ width: "calc(100% - 280px)" }}>
                <WalletsData setInquiryId={() => { }} />
                {loading ? (
                    <>
                    <Spinner/>
                    <div className="h4 text-center"> {t('views.balance.waitingBalance')}</div>
                    <BalanceLoader/>
                    </>
                ) : (
                    <>
                        <div className="row my-4">
                            <h2 className="h6 text-muted m-0">{t('views.balance.value')}</h2>
                            <h3 className="h2 m-0">{balanceFiat ? balanceFiat.toFixed(2) : '0.0'}&nbsp;&#36;</h3>
                        </div>
                        <Filters currentBalances={balances} iconBlockchain={iconBlockchain} setFiltered={setFiltered} 
                            enableAllFilters={enableAllFilters} setEnableAllFilters={setEnableAllFilters}/>
                        <div className="row rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
                            {( filtered.length === 0) ? (
                                <div className="my-5 text-center">
                                    <h4 className="h6">{t('views.balance.noTokens')}</h4>
                                    {/* <p className="text-muted">Don't see a token?</p> */}
                                    <button onClick={() => setEnableAllFilters(true)} className="btn btn-primary px-3 text-nowrap" aria-current="page">
                                        <i className="bi bi-arrow-repeat"></i><span className="ms-3">{t('views.historicView.selectAllFilters')}</span>
                                    </button>
                                </div>
                                ) : (
                            <table className="table table-striped table-hover table-borderless align-middle">
                                <thead className="sticky-top">
                                    <tr className="text-light-emphasis">
                                        {/* <th scope="col">{t('views.balance.blockchain')}</th> */}
                                        <th scope="col">{t('views.balance.wallet')}</th>
                                        <th scope="col"></th>
                                        <th scope="col" >{t('views.balance.symbol')}</th>
                                        <th scope="col">{t('views.balance.tokenName')}</th>
                                        <th scope="col">{t('views.balance.contractAddress')}</th>
                                        <th scope="col">{t('views.balance.tokenType')}</th>
                                        <th scope="col" style={{textAlign: 'end'}}>{t('views.balance.balance')} <i className="bi bi-info-circle-fill text-muted" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-custom-class="tooltip-popover popover-sm" data-bs-content="Popover con información al respecto de esta columna..."></i></th>
                                        <th scope="col" style={{textAlign: 'end'}}>{t('views.balance.balance')} {t('views.balance.currencyType')}</th>
                                    </tr>
                                </thead>
                                {balances.length > 0 && filtered.length === 0 && (
                                    <tbody>
                                        {balances.map((balance: CurrentBalance, index1: number) => (
                                            balance.balance.map((bal: BalanceDetail, index2: number) => (
                                                bal?.tokens?.map((token: Token, index3: number) => (
                                                    <tr key={index1 + '_' + index2 + '_' + index3}>
                                                        <RowTokenTable index={(index1 * balance.balance.length * bal.tokens.length) + (index2 * bal.tokens.length) + index3} balance={balance} token={token} iconBlockchain={iconBlockchain} />
                                                    </tr>
                                                ))
                                            ))
                                        ))}

                                    </tbody>
                                )}
                                {filtered.length > 0 && (
                                    <tbody>
                                        {filtered.map((balance: CurrentBalance, index1: number) => (
                                            balance.balance.map((bal: BalanceDetail, index2: number) => (
                                                bal?.tokens?.map((token: Token, index3: number) => (
                                                    <tr key={index1 + '_' + index2 + '_' + index3}>
                                                        <RowTokenTable index={(index1 * balance.balance.length * bal.tokens.length) + (index2 * bal.tokens.length) + index3} balance={balance} token={token} iconBlockchain={iconBlockchain} />
                                                    </tr>
                                                ))
                                            ))
                                        ))}
                                    </tbody>
                                )}
                            </table>
                            )}
                        </div>
                    </>)}
            </div>
        </>
    )
}

export default Balance;