import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserInfo, USER_TYPE } from "../features/user/user";
import { Inquiry } from "../models/models";

type HistoricTableProps = {
    historic : Inquiry[];
    setShowDetails : any;
    setInquiryId : any;
    iconBlockchain : any;
    showModalCertificate : any
    showModalUploadCertificate : any;
    enableAllFilters : any;
}

function HistoricTable( {historic, setShowDetails, setInquiryId, iconBlockchain, showModalCertificate, showModalUploadCertificate, enableAllFilters} : HistoricTableProps ) {


    const userInfo = useSelector(selectUserInfo);
    const { t } = useTranslation('common');

    return (
        <div className="row mt-2 rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
            {historic?.length > 0 ? (
                <table className="table table-striped table-hover table-borderless align-middle">
                    <thead className="sticky-top">
                        <tr className="text-light-emphasis">
                            <th scope="col">{t('components.historicTable.blockchains')}</th>
                            <th scope="col">{t('components.historicTable.date')}</th>
                            <th scope="col">{t('components.historicTable.credits')}</th>
                            {userInfo.userType === USER_TYPE.ADMIN ? (
                                <th scope="col">{t('components.historicTable.user')}</th>
                            ) : <th scope="col">{t('components.historicTable.wallet')}</th>}
                            <th scope="col">{t('components.historicTable.inquiryDate')}</th>
                            <th scope="col">{t('components.historicTable.timezone')}</th>
                            <th scope="col">{t('components.historicTable.balance')}</th>
                            <th scope="col">{t('components.historicTable.certificate')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historic.map((inquiry: Inquiry, index: number) => (
                            <tr key={index}>
                                {inquiry.configuration ? (
                                    <td className="text-nowrap" style={{ maxWidth: '90px' }}>
                                        <span className="btn-image-md">
                                            {inquiry.configuration.blockchains.map((bc, index) => (
                                                <img className="m-1" key={index} src={iconBlockchain(bc)} alt="Imagen" />
                                            ))}
                                        </span>

                                    </td>
                                ) : (
                                    <td className="text-nowrap">{t('components.historicTable.unknown')}</td>
                                )}

                                <td className="text-nowrap" style={{ maxWidth: '90px' }}>{new Date(inquiry.createdAt).toDateString()}</td>
                                {/* <td className="text-nowrap" style={{ maxWidth: '20px' }}>{inquiry.consumed ? "YES" : "NO"}</td> */}
                                <td className="text-nowrap text-center" style={{ maxWidth: '20px' }}>{inquiry.credits}</td>

                                {
                                userInfo.userType === USER_TYPE.ADMIN ? (
                                    <th scope="col">
                                    {inquiry.configuration.wallets.map((str, index) => (
                                        <div key={index}>
                                        {str.length > 20
                                            ? `${str.slice(0, 10)}...${str.slice(-10)}`
                                            : str}
                                        </div>
                                    ))}
                                    </th>
                                ) : inquiry.configuration ? (
                                    <td className="text-nowrap" style={{ maxWidth: '250px', wordBreak: 'break-word' }}>
                                    {inquiry.configuration.wallets.map((str, index) => (
                                        <div key={index}>
                                            {str.length > 20
                                                ? `${str.slice(0, 6)}...${str.slice(-6)}`
                                                : str} 
                                        </div> ))} </td>) : (<td className="text-nowrap">N/A</td>)
                                }

                                {inquiry.configuration ? (<td className="" style={{ maxWidth: '100px', wordBreak: 'break-word' }}>{inquiry.configuration.dateSelected}</td>) : (<td className="text-nowrap">{t('components.historicTable.unknown')}</td>)}
                                {inquiry.configuration ? (<td className="" style={{ maxWidth: '100px', wordBreak: 'break-word' }}>{inquiry.configuration.timeZone}</td>) : (<td className="text-nowrap">{t('components.historicTable.unknown')}</td>)}

                                <td className="">
                                    <div className="d-grid">
                                        {inquiry.consumed && (<div className="btn-group" role="group" aria-label="Generar Certificado">
                                            <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                className="bi bi-card-list"></i></button>
                                            <button type="button" onClick={() => { setShowDetails(true); setInquiryId(inquiry._id) }} disabled={!inquiry.consumed}
                                                className="btn btn-primary border-start-none text-start">{t('components.historicTable.details')}</button>
                                        </div>)}
                                        {!inquiry.consumed && (<div className="btn-group" role="group" aria-label="Generar Certificado">
                                            <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                className="bi bi-arrow-clockwise"></i> </button>
                                            <button type="button" onClick={() => { setShowDetails(true); setInquiryId(inquiry._id) }}
                                                className="btn btn-primary border-start-none text-start">{t('components.historicTable.recover')}</button>
                                        </div>)}
                                    </div>
                                </td>
                                <td className="">
                                    <div className="d-grid ">
                                        {userInfo.userType === USER_TYPE.ADMIN && (<div className="btn-group" role="group" aria-label="Generar Certificado">
                                            <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                className="bi bi-patch-check"></i></button>
                                            {inquiry.certificate ? (
                                                <button type="button" onClick={() => window.open(inquiry.certificate?.fileSigned ? inquiry.certificate?.fileSigned : inquiry.certificate?.fileUnsigned, '_blank')}
                                                    className="btn btn-primary border-start-none text-start">{inquiry.certificate?.fileSigned ? 'View Certificate signed' : 'View Certificate'}</button>
                                            ) :
                                                (<button type="button" onClick={() => { showModalCertificate(true); setInquiryId(inquiry._id) }} disabled={!inquiry.consumed}
                                                    className="btn btn-primary border-start-none text-start">{t('components.historicTable.requestCertificate')}</button>)}

                                        </div>)}
                                        {userInfo.userType === USER_TYPE.ADMIN && inquiry.certificate?.fileUnsigned && (<>
                                            <div className="btn-group mt-2 " role="group" aria-label="Generar Certificado">
                                                <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                    className="bi bi-patch-check"></i></button>
                                                <button type="button" onClick={() => window.open(inquiry.certificate?.fileSigned ? inquiry.certificate?.fileSigned : inquiry.certificate?.fileUnsigned, '_blank')}
                                                    className="btn btn-primary  text-start">{inquiry.certificate?.fileSigned ? 'Certificate signed' : 'Get Certificate'}</button>
                                            </div>
                                            {!inquiry.certificate?.fileSigned && (<div className="btn-group mt-2 " role="group" aria-label="Generar Certificado">
                                                <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                    className="bi bi-patch-check"></i></button>
                                                <button type="button" onClick={() => { setInquiryId(inquiry._id); showModalUploadCertificate(true) }}
                                                    className="btn btn-primary  text-start">{t('components.historicTable.signedUpload')}</button>
                                            </div>)}
                                        </>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            ) : 
            (
                <>
                <div className="my-5 text-center">
                    <h4 className="h6">{t('views.historicView.noInquirires')}</h4>
                    {/* <p className="text-muted">Don't see a token?</p> */}
                    <button onClick={enableAllFilters} className="btn btn-primary px-3 text-nowrap" aria-current="page">
                        <i className="bi bi-arrow-repeat"></i><span className="ms-3">{t('views.historicView.selectAllFilters')}</span>
                    </button>
                </div>
                </>
            )}
            

        </div>
    )
}

export default HistoricTable;