import { useState } from "react";
import { useSelector } from "react-redux";
import FormProfile from "../components/FormProfile";
import WalletsData from "../components/walletsData";
import { selectUserInfo } from "../features/user/user";
import FormInvoice from "../components/FormInvoice";
import { t } from "i18next";
import { useTranslation } from "react-i18next";


function Profile() {


    const userInfo = useSelector(selectUserInfo);
    const [userData,setUserDataLocal] = useState<any>({...userInfo.userData, correctData: true});
    const { t } = useTranslation('common');

    return (
        <>
            <div id="dashboard" className="d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto" style={{ width: "calc(100% - 280px)" }}>
                <WalletsData setInquiryId={() => {}} />
                <div className="row row-gap-3 my-4">
                    <div className="mt-4 col-6">
                        <FormProfile showSaveButton={true} showSaveData={false} userData={userData} showTimeZone={true} setUserDataLocal={setUserDataLocal}/>
                    </div>
                    <div className="col-6">
                       <FormInvoice/>
                    </div>
                </div>
                <div className="row px-1 py-4 rounded-top-4 mt-auto text-light-emphasis bg-light-subtle">
                    <h3 className="h5">{t('views.profile.paymentHistory')}</h3>
                </div>
                <div className="row rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
                    <table className="table table-striped table-hover table-borderless align-middle">
                        <thead className="sticky-top">
                            <tr className="text-light-emphasis">
                                <th scope="col" className="text-center px-2">{t('views.profile.pad')}</th>
                                <th scope="col">{t('views.profile.pack')}</th>
                                <th scope="col">{t('views.profile.date')}</th>
                                <th scope="col">{t('views.profile.price')}</th>
                                <th scope="col">{t('views.profile.availability')}<i className="bi bi-info-circle-fill text-muted" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-custom-class="tooltip-popover popover-sm" data-bs-content="Popover con información al respecto de esta columna..."></i></th>
                                <th scope="col">{t('views.profile.invoice')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" className="text-center fw-light px-2">1</th>
                                <td className="text-nowrap">{t('views.profile.randomPack')}</td>
                                <td className="text-nowrap">{t('views.profile.randomDate')}</td>
                                <td className="text-nowrap">{t('views.profile.randomPrice')}</td>
                                <td className="text-nowrap text-enganioso-ranking"><i className="bi bi-x-circle icon-md"></i><span className="ms-2 align-middle-md">{t('views.profile.used')}</span></td>
                                <td className="">
                                    <div className="d-grid">
                                        <div className="btn-group" role="group" aria-label="Generar Certificado">
                                            <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i className="bi bi-file-earmark-arrow-down"></i></button>
                                            <button type="button" className="btn btn-primary border-start-none text-start">{t('views.profile.invoiceRequest')}</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="text-center fw-light px-2">2</th>
                                <td className="text-nowrap">{t('views.profile.randomPack')}</td>
                                <td className="text-nowrap">{t('views.profile.randomDate')}</td>
                                <td className="text-nowrap">{t('views.profile.randomPrice')}</td>
                                <td className="text-nowrap text-valido-ranking"><i className="bi bi-check-circle icon-md"></i><span className="ms-2 align-middle-md">{t('views.profile.available')}</span></td>
                                <td className="">
                                    <div className="d-grid">
                                        <div className="btn-group" role="group" aria-label="Generar Certificado">
                                            <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i className="bi bi-file-earmark-arrow-down"></i></button>
                                            <button type="button" className="btn btn-primary border-start-none text-start">{t('views.profile.invoiceRequest')}</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Profile;