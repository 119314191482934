import axios from "axios";
// import { useSelector } from "react-redux";
// import { selectJWT, UserInfo } from "../features/user/user";
// import FileService, { FileType } from "./file.service";

class LoginService {

    // JWT = useSelector(selectJWT);
    // fileService = new FileService();

    // Auth endpoints
    async signUp(wallet: string, email: string) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! +  '/access/v1/auth/signup', {wallet, email});
            return response?.data?.result;
        } catch (error) {
            console.error('Error on sign up');
            return undefined;
        }
    }

    async signIn(wallet: string, signature: string) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! +  '/access/v1/auth/signin', {wallet, signature});
            return response?.data?.result;
        } catch (error) {
            console.error('Error on sign up');
            return undefined;
        }
    }

    // User endpoints
    async findUserByWalletId(walletId: string) {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL! + `/api/v1/users/search/${walletId}`);
            return response?.data?.result;
        } catch (error: any) {
            console.error('Error on searching user');
            if (error?.code === "ERR_NETWORK") {
                throw new Error('Connection error, API is down');
            }
            return undefined;
        }
    }


}

export default LoginService;