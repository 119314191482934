import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import WalletsData from "../components/walletsData";
import { selectHistoric, selectJWT, setError, setHistoric } from "../features/user/user";
import { BLOCKCHAIN, Inquiry } from "../models/models";
import { Modal } from "react-bootstrap";
import BalanceTable from "../components/BalanceTable";
import BalanceService from "../services/balance.service";
import { useAppDispatch } from "../app/hooks";
import BalanceLoader from "../loaders/BalanceLoader";
import { useNavigate } from "react-router-dom";
import { AdminService, FileAWS } from "../services/admin.service";
import FiltersHistoric, { WalletSelected } from "../components/FiltersHistoric";
import HistoricTable from "../components/HistoricTable";
import CertificateGenerator from "../components/Certificate";
import { useTranslation } from "react-i18next";


function Historic() {

    const jwt = useSelector(selectJWT);
    const [historic, setHistoricInquiries] = useState<Inquiry[]>([]);
    const [historicFilterd, setHistoricInquiriesFiltered] = useState<Inquiry[]>([]);
    const historicStore = useSelector(selectHistoric);
    const [inquiryId, setInquiryId] = useState<any>('');
    const [showDetails, setShowDetails] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [modalCertificate, showModalCertificate] = useState<boolean>(false);
    const [modalUploadCertificate, showModalUploadCertificate] = useState<boolean>(false);
    const [file, setFile] = useState<any>();
    const [wallets, setWallets] = useState<WalletSelected[]>([]);
    const [enableAllFilters, setEnableAllFilters] = useState(false);
    const { t } = useTranslation('common');

    const saveFile = async ( event : any) => {
        
        event.preventDefault()

        try {
            if (event.target.files.length > 0) {
                console.log("SAVE FILE:", event.target.files[0])
                setFile(event.target.files[0])
            }
        } catch (error) {
            alert("ERROR");
        }   
    }

    const submitCertificate = async ( event : any) => {
        console.log("SUBMIT")
        
        event.preventDefault();

        try {
            if(!file) throw new Error('No file');

            const inq = historic.find( (inquiry : Inquiry) => inquiry._id === inquiryId );

            if(!inq?.certificate?._id) throw new Error('No cert');

            const uploaded : FileAWS | undefined = await AdminService.uploadFile( inq.certificate._id,file,jwt);
            
            if(!uploaded)throw new Error('Error upload');

            //dispatch signed Contract
            
            console.log("Uploaded", uploaded);
        } catch (error) {
            alert("ERROR");
        }
    
    }

    useEffect(() => {

        const callBalance = async () => {

            try {
                const inquiries: Inquiry[] = await BalanceService.historic(jwt);
                if (inquiries.length > 0) {
                    setHistoricInquiries(inquiries);
                    setWallets(getWallets(inquiries));
                    dispatch(setHistoric(inquiries))
                }
                setLoading(false);
            } catch (error: any) {
                dispatch(setError({ title: 'Historic error', desc: 'Error has ocurred trying get your inquiry history' }));
            }

        }

        const getWallets = (inquiries: Inquiry[]): WalletSelected[] => {
            const walletsList: WalletSelected[] = [];
            inquiries.forEach(inq => {
                inq.configuration.wallets?.forEach(wallet => {
                        if (!walletsList.find(w => w.wallet === wallet)) {
                            walletsList.push({ wallet, selected: true });
                        }
                    });
                
            });
            // setWallets(walletsList);
            return walletsList;
        }

        if (historicStore.length > 0) {
            setHistoricInquiries(historicStore);
            // applyFilters(historicStore);
            setWallets(getWallets(historicStore));
            setLoading(false);
        }
        else {
            callBalance();
        }


    }, [historicStore, dispatch, jwt]);

    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }

    return (
        <>
            <div id="dashboard" className="d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto" style={{ width: "calc(100% - 280px)" }}>
                <WalletsData setInquiryId={() => { }} />
                {historic.length !== 0 && (
                <FiltersHistoric iconBlockchain={iconBlockchain} inquiries={historic} wallets={wallets}
                        setHistoricInquiriesFiltered={setHistoricInquiriesFiltered} enableAllFilters={enableAllFilters} setEnableAllFilters={setEnableAllFilters}/>
                )}
                 <div className="row px-1 py-2 mt-5 mb-5rounded-top-4 mt-auto text-light-emphasis bg-light-subtle">
                </div>
                {!loading && historic.length === 0 && (
                    <div className="my-5 text-center">
                        <h4 className="h6">{t('views.historicView.noInquiries')}</h4>
                        <p className="text-muted">{t('views.historicView.noSeeInquiries')}</p>
                        <button className="btn btn-primary px-3 text-nowrap" aria-current="page"
                            onClick={() => dispatch(setError({ title: 'Historic error', desc: '' }))}>
                            <i className="bi bi-arrow-repeat"></i><span className="ms-3">{t('views.historicView.support')}</span>
                        </button>
                    </div>
                )}
                {loading && (
                    <BalanceLoader />
                )}
                {!loading && (
                    <HistoricTable  
                        historic={historicFilterd} setShowDetails={setShowDetails} setInquiryId={setInquiryId} 
                        iconBlockchain={iconBlockchain} showModalCertificate={showModalCertificate} 
                        showModalUploadCertificate={showModalUploadCertificate}
                        enableAllFilters={() => setEnableAllFilters(true) }
                    />
                )}

            </div>
            <Modal
                onHide={() => setShowDetails}
                show={showDetails}
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>{t('views.historicView.balanceDetails')}</Modal.Title>
                    <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setShowDetails(false) }} aria-label="Close"></button>
                    
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex justify-content-end m-2">
                    <button type="button" className="btn btn-primary btn-sm px-3 text-nowrap" onClick={() => { navigate('/details/'+inquiryId) }} aria-label="Details">
                    {t('views.historicView.fullScreen')}&nbsp;&nbsp;<i className="bi bi-arrows-fullscreen"></i>
                    </button>
                    </div>
                    <BalanceTable inquiryId={inquiryId} historic={true} setShow={setShowDetails} iconBlockchain={iconBlockchain} />
                </Modal.Body>
            </Modal>
            { modalCertificate && inquiryId && (
                <CertificateGenerator inquiry={inquiryId} />
            )}
            
            <Modal
                onHide={() => showModalUploadCertificate(false)}
                show={modalUploadCertificate}
                size="lg"
                centered
            >
                <Modal.Body>
                    <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3"
                        onClick={() => showModalUploadCertificate(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="container">
						<div className="p-3 my-4 text-center">
							<div className="mb-4">
								<i className="display-5 text-info-ranking bi bi-upload"></i>
							</div>
							<h1 className="h2">{t('views.historicView.uploadCertificate')}</h1>
							{/* <p>Subir un certificado pendiente.</p> */}
							<form className="form-floating-grup mt-4 d-grid gap-3 text-start needs-validation" onSubmit={submitCertificate}>
								<div>
									<label id="formFileLg" className="form-label">{t('views.historicView.selectFile')}</label>
									<input className="form-control form-control-lg" id="formFileLg" type="file" onChange={saveFile} multiple={false}/>
                                    
								</div>
								<button type="submit" className="btn btn-primary">
                                    {t('views.historicView.saveButton')}
								</button>
							</form>
						</div>
					</div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Historic;