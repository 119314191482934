import { UserState } from "../features/user/user";

export const loadState = () : UserState => {
    try {
      const serializedState = sessionStorage.getItem('userState');

      if (serializedState === null) {
        return getInitialState();
      }

      return JSON.parse(serializedState);
    } catch (error) {
        return getInitialState();
    }
  };

  const getInitialState = () : UserState =>{
    return  {
        wallet:'',
        status: 'ok',
        jwt: '',
        userInfo : {
          email: '',
          name : '',
          url : '',
          img : '',
          banner : '',
          alias : ''
        },
        error : {},
        notification : {},
        balances : [],
        historic : [],
        plans : [],
        tokens : [],
        users : [],
        totalFiat : 0
    };
  }

  export const saveState = (userState : UserState) => {
    try {
      const serializedState = JSON.stringify(userState);
      sessionStorage.setItem('userState', serializedState);
    } catch (error) {
      // Ignore write errors.
    }
  };