import { useEffect, useState } from "react";
import { BLOCKCHAIN, Inquiry } from "../models/models"
import { useAppDispatch } from "../app/hooks";
import { setError } from "../features/user/user";
import { useTranslation } from "react-i18next";

export type WalletSelected = {
    wallet : string,
    selected: boolean
}

type FiltersHistoricProps = {
    iconBlockchain: any
    inquiries: Inquiry[];
    setHistoricInquiriesFiltered: any;
    wallets : WalletSelected[],
    enableAllFilters : boolean, 
    setEnableAllFilters : any
}

type FilterHistoricValues = {
    ethereum: boolean;
    bsc: boolean;
    polygon: boolean;
    wallets : WalletSelected[] ;
}

function FiltersHistoric({ iconBlockchain, inquiries, setHistoricInquiriesFiltered,wallets, enableAllFilters, setEnableAllFilters}: FiltersHistoricProps) {

    
    const [filterValues, setFilterValues] = useState<FilterHistoricValues>({ ethereum: true, bsc: true, polygon: true , wallets});
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');
 
    const handleWallets = ( wallet : WalletSelected) => {
        const walletsCopy : WalletSelected[] = [];

        filterValues.wallets.forEach(wall => {
            if(wall.wallet === wallet.wallet){
                walletsCopy.push({wallet : wall.wallet , selected : !wall.selected})
            }
            else{
                walletsCopy.push(wall);
            }
        } )

        setFilterValues({...filterValues, wallets : walletsCopy});
        // setWallets(walletsCopy);
    }

    

    

    useEffect(() => {


        const filterInquiries = (inquiries: Inquiry[]) => {
            const inquiriesFiltered: Inquiry[] = []
            inquiries.forEach((inquiry: Inquiry) => {

                if(!filterValues.wallets.find( wall => inquiry.configuration.wallets.includes(wall.wallet)  && wall.selected)){
                    return false;
                }

                if (!filterValues.ethereum && !filterValues.polygon && !filterValues.bsc ){
                    return;
                }

                if (filterValues.ethereum && !inquiry.configuration?.blockchains?.includes(BLOCKCHAIN.ETHEREUM)) {
                    console.log("eth");
                    return false;
                }
                if (filterValues.polygon && !inquiry.configuration?.blockchains?.includes(BLOCKCHAIN.POLYGON)) {
                    console.log("polygon");
                    return false;
                }
                if (filterValues.bsc && !inquiry.configuration?.blockchains?.includes(BLOCKCHAIN.BSC)) {
                    console.log("bsc");
                    return false;
                }
                inquiriesFiltered.push(inquiry);
            });

            return inquiriesFiltered;
        }
        
        const enableFilters = ( ) => {
            const walletsCopy : WalletSelected[] = [];
    
            filterValues.wallets.forEach(wall => {
                walletsCopy.push({wallet : wall.wallet , selected : true})
            } )
    
            setFilterValues({ethereum: true, bsc: true, polygon: true, wallets : walletsCopy});
        }

        try {
            console.log("Enable : ", enableAllFilters);
            if( enableAllFilters){
                enableFilters();
                setEnableAllFilters(false);
            }
            else if (inquiries) {
                // console.log(inquiries);
                // getWallets(inquiries);
                const inquiriesFiltered = filterInquiries(inquiries);
                console.log(inquiriesFiltered);
                setHistoricInquiriesFiltered(inquiriesFiltered)
                // console.log("inquiries FILTERED: ", inquiriesFiltered);
            }
        } catch (error) {
            dispatch(setError({ title: 'Historic error', desc: 'Error trying apply your filters' }));
        }


    }, [enableAllFilters,filterValues,dispatch, inquiries,setHistoricInquiriesFiltered]);

    return (<>
        <div className="row px-1 py-4 rounded-top-4 mt-auto text-light-emphasis  back-filters" >
            <div className="mt-2 col-12 d-flex justify-content-center">
                <span className="h2 text-nowrap text-white  align-middle-lg ">{t('components.filtersHistoric.selectFilter')}</span>
            </div>
            <div className="row mt-4 row-gap-3 me-1" style={ { maxWidth : '100%'}} >
                <div className="row d-flex justify-content-center" >
                    {/* <div className="dropdown text-nowrap" > */}
                        {filterValues.wallets?.map((walletChoosen: WalletSelected, index: number) => (
                            <div key={index} onClick={() => { handleWallets( walletChoosen) }}
                                className={walletChoosen.selected ? "m-2 btn btn-primary btn-wallets-2 w-100 my-1 text-center selected" :  "m-2 btn btn-light btn-wallets w-100 my-1 text-center selected"}>
                                {walletChoosen.wallet.length > 20
                                        ? `${walletChoosen.wallet.slice(0, 6)}...${walletChoosen.wallet.slice(-6)}`
                                        : walletChoosen.wallet}
                            </div>
                        ))}

                    {/* </div> */}
                </div>
                <div className="my-auto ms-auto">
                    
                    <div className="col d-flex justify-content-center ">
                        <div  className={"" + (filterValues.ethereum ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                        onClick={() => setFilterValues({ ...filterValues, ethereum: !filterValues.ethereum })}>
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                            </span>
                            {t('components.filtersHistoric.eth')}
                        </div>
                        <div  className={"" + (filterValues.polygon ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                        onClick={() => setFilterValues({ ...filterValues, polygon: !filterValues.polygon })}>
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                            </span>
                            {t('components.filtersHistoric.poly')}
                        </div>
                        <div  className={"" + (filterValues.bsc ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                        onClick={() => setFilterValues({ ...filterValues, bsc: !filterValues.bsc })}>
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                            </span>
                            {t('components.filtersHistoric.bsc')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default FiltersHistoric;